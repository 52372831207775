import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { ImageEditorComponent as EditorComp } from '@syncfusion/ej2-react-image-editor';
import { blobToBase64 } from '../../utils/etc';

const SpinnerWrapper = styled.div`
  padding: 130px 50px;
  text-align: center;
`;

const toolbarItems = [
  'Crop',
  'Straightening',
  'Annotate',
  'Transform',
  'Finetunes',
  'Filters',
  'Frame',
  'Resize',
  'ZoomIn',
  'ZoomOut',
  // 'Save',
  // 'Open',
  'Undo',
  'Redo',
  'Reset',
];

const ImageEditorComponent = ({ image, editorRef, loading }) => {
  useEffect(() => {
    getFile();
    // eslint-disable-next-line
  }, []);

  const getFile = async () => {
    const blob = await fetch(image.url).then(response => response.blob());
    const baseFile = await blobToBase64(blob);
    editorRef.current.open(baseFile);
    setShapesStrokes();
  };

  const setShapesStrokes = () => {
    editorRef.current.updateShape({
      type: 'Rectangle',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'Ellipse',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'Line',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'Arrow',
      strokeWidth: 6,
    });
    editorRef.current.updateShape({
      type: 'Path',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'Text',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'FreehandDraw',
      strokeWidth: 8,
    });
    editorRef.current.updateShape({
      type: 'Image',
      strokeWidth: 8,
    });
  };

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spin size='large' />
      </SpinnerWrapper>
    );
  }

  return <EditorComp ref={editorRef} toolbar={toolbarItems} />;
};

export default React.memo(ImageEditorComponent);
