import client from './main-client';

export const fetchAllConditionsToUsersApi = params =>
  client().get('/conditions-to-users', { params });
export const fetchOneConditionsToUserApi = ({ id, ...params }) =>
  client().get(`/conditions-to-users/${id}`, { params });
export const updateConditionsToUserApi = params =>
  client().put(`/conditions-to-users/${params.id}`, params.values);
export const addConditionsToUserApi = data => client().post('/conditions-to-users', data);
export const deleteConditionsToUserApi = params =>
  client().delete(`/conditions-to-users/${params.id}`, { params });
